.score-card {
	background-color: rgb(1, 62, 1);
	border: 2px solid rgb(93, 115, 85);
	box-shadow: 0px 2px 5px rgb(31, 31, 31);
	border-radius: 3px;
	margin-bottom: 0.5em;
}

@media (min-width: 601px) {
	.score-card:hover {
		border: 2px solid rgb(137, 194, 5);
		box-shadow: 0px 0px 2px rgb(181, 255, 7);
	}
}

@media (max-width: 600px) {
	.score-card:hover {
		border: 2px solid rgb(137, 194, 5);
		box-shadow: none;
	}
}

.sc-name {
	margin-left: 2em;
	margin-right: 3em;
}

.sc-score {
	margin-right: 2em;
}

.score-card {
	font-family: 'Graduate', Arial, Helvetica, sans-serif;
	font-size: 20px;
}
